@import "../../../css//mixins.scss";

.cards {
  padding-top:5rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 70%;
  justify-content: space-evenly;
  @include moveUpChildren(4);
}

@media(max-width:470px){
  .cards{
    width: 100%;
  }
}