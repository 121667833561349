@import "../../../css//mixins.scss";

.skills{
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(8rem, 8rem));
    grid-template-rows: repeat(3, 1fr);
    @include moveUpChildren(20);
}

.skill-hexagon {
    width: 14rem;
    height: 16rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -2rem;
    transition: 0.2s ease-out;
    color: #fff;
    text-align: center;
    font-size: 1.6rem;
    background-image: linear-gradient(to top left, rgba(36,57,73,0.3), rgba(81,127,164,0.3));
    @include hexagon;
    &:hover {
        background-color: rgba(204,204,204,0.1);
    }
}

.emphasis {
    background-image: linear-gradient(to bottom right, rgba(#3a7bd5, 0.5) , rgba(#3a6073, 0.5));
    &:hover{
        background-color: rgba(204,204,204,0.2);
    }
}

.row {
    display: flex;
    flex-direction: row;
}

@media (max-width: 925px){
    
    .skills {
      margin-top: 24rem; 
      transform: rotate(-90deg);
      margin-bottom: 14rem;
    }

    .skill-hexagon span {
        transform: rotate(90deg);
    }
  }
   