@import "../../../../css/mixins.scss";
$card-gradient: linear-gradient(45deg, rgba(79, 79, 79, 0.03) 0%, rgba(79, 79, 79, 0.03) 25%,rgba(215, 215, 215, 0.03) 25%, rgba(215, 215, 215, 0.03) 100%),linear-gradient(0deg, rgba(174, 174, 174, 0.1) 0%, rgba(174, 174, 174, 0.1) 36%,rgba(189, 189, 189, 0.1) 36%, rgba(189, 189, 189, 0.1) 100%),linear-gradient(0deg, rgba(197, 197, 197, 0.08) 0%, rgba(197, 197, 197, 0.08) 61%,rgba(118, 118, 118, 0.08) 61%, rgba(118, 118, 118, 0.08) 100%),linear-gradient(45deg, rgba(50, 50, 50, 0.08) 0%, rgba(50, 50, 50, 0.08) 3%,rgba(30, 30, 30, 0.08) 3%, rgba(30, 30, 30, 0.08) 100%),linear-gradient(0deg, rgba(10, 10, 10, 0.03) 0%, rgba(10, 10, 10, 0.03) 64%,rgba(112, 112, 112, 0.03) 64%, rgba(112, 112, 112, 0.03) 100%),linear-gradient(135deg, rgba(65, 65, 65, 0.09) 0%, rgba(65, 65, 65, 0.09) 44%,rgba(236, 236, 236, 0.09) 44%, rgba(236, 236, 236, 0.09) 100%),linear-gradient(90deg, rgba(124, 124, 124, 0.03) 0%, rgba(124, 124, 124, 0.03) 81%,rgba(138, 138, 138, 0.03) 81%, rgba(138, 138, 138, 0.03) 100%),linear-gradient(90deg, hsl(189,61%,44%),hsl(189,61%,44%));// big monitor club
.card {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: space-between;
    width: 45rem;
    color: #d4d4d4;
    border-radius: 0.5rem;
    background-color: rgba(243,243,243,0.9);
    box-shadow: 0 1.5rem 2rem rgba(0,0,0,0.2), 0 2rem 5rem rgba(0,0,0,0.2);
    margin-bottom: 4rem;
    &__text {
        font-size: 1.6rem;
        padding: 1rem 1.5rem;
        line-height: 1.8;
        text-align: center;
        color: #00343f;
    }
    &__title {
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        background-image: linear-gradient(to right bottom, #00343f, #330867);
        font-size: 2.5rem;
        padding: 0.5rem;
        text-align: center;
        text-transform: uppercase;
        font-weight: 500;
    }
    &__content {
        flex: 1;
        min-height: 35rem;
    }
    &__top {
        height: 15rem;
        display: flex;
        align-items: center;
        justify-content: center;
        clip-path: polygon(0 0, 100% 0, 100% 90%, 50% 100%, 0 90%);
        border-top-right-radius: 0.5rem;
        border-top-left-radius: 0.5rem;
    }
    &__bottom {
        border-bottom-right-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
        height: 13rem;
        width: 100%;
        bottom: 0;
        z-index: 1;
        color: #fff;
        padding: 2rem 0;
        clip-path: polygon(0 10%, 50% 0%, 100% 10%, 100% 100%, 0 100%);
        height: 15rem;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
       
    }
    &__circle {
        @include iconCircle(7);
    }
    &__icon{
        color: #00343f;
        font-size: 4rem;
    }
    &__list-items{
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        padding: 1rem 3rem;
        justify-content: space-evenly; 
    }
    &__list-item {
        font-size: 1.8rem;
        list-style: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 1rem;
        &:not(:last-child){
            margin-right: 1rem;
        }
    }
    &__hexagon {
        width: 2.4rem;
        height: 2rem;
        margin-right: 0.5rem;
        clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
    }
    &__background{
        z-index: -1;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 1;
        background-image: $card-gradient;
        border-bottom-right-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        border-top-left-radius: 0.5rem;
    }
}
