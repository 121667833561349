@keyframes moveinbottom {
    0% {
        opacity: 0;
        transform:translatey(10rem);
    }
    70% {
        transform:translatey(-1.5rem);
    }
    100%{
        opacity: 1;
        transform: translate(0);
    }
  }