@import "../../../../css/mixins.scss";

.card {
    color: #00343f;
    background-color: rgba(243,243,243,0.9);
    box-shadow: 0 1.5rem 2rem rgba(0,0,0,0.2), 0 2rem 5rem rgba(0,0,0,0.2);
    width: 65rem;
    min-height: 25rem;
    margin-top: 4rem;
    border-radius: 0.5rem;
    position: relative;
    display: flex;
    flex-direction: row;
    &__text {
        font-size: 1.6rem;
        padding: 0 2rem 2rem 2rem;
    }
    &__title {
        text-transform: uppercase;
        font-size: 3rem;
        text-align: left;
        padding-top: 2rem;
    }
    &__content {
        width: 80%;
    }
    &__side {
        border-radius: 0.5rem;
        height: 100%;
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: static;
        clip-path: polygon(0 0, 80% 0, 100% 50%, 80% 100% , 0 100%);
    }
    &__circle {
        @include iconCircle(7);
    }
    &__icon{
        color: #00343f;
        font-size: 4rem;
    }


}
