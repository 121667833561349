@import "../../../css//mixins.scss";

.contact {
    color: rgba(255, 255, 255, .8);
    height: 10rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 2rem;
    transition: all 0.2s;
    text-decoration: none;
    padding: 0 2rem;
    @include moveUpChildren(2);
    &__circle {
        @include iconCircle(7);
        background-image: linear-gradient(45deg, rgba(79, 79, 79, 0.03) 0%, rgba(79, 79, 79, 0.03) 25%,rgba(215, 215, 215, 0.03) 25%, rgba(215, 215, 215, 0.03) 100%),linear-gradient(0deg, rgba(174, 174, 174, 0.1) 0%, rgba(174, 174, 174, 0.1) 36%,rgba(189, 189, 189, 0.1) 36%, rgba(189, 189, 189, 0.1) 100%),linear-gradient(0deg, rgba(197, 197, 197, 0.08) 0%, rgba(197, 197, 197, 0.08) 61%,rgba(118, 118, 118, 0.08) 61%, rgba(118, 118, 118, 0.08) 100%),linear-gradient(45deg, rgba(50, 50, 50, 0.08) 0%, rgba(50, 50, 50, 0.08) 3%,rgba(30, 30, 30, 0.08) 3%, rgba(30, 30, 30, 0.08) 100%),linear-gradient(0deg, rgba(10, 10, 10, 0.03) 0%, rgba(10, 10, 10, 0.03) 64%,rgba(112, 112, 112, 0.03) 64%, rgba(112, 112, 112, 0.03) 100%),linear-gradient(135deg, rgba(65, 65, 65, 0.09) 0%, rgba(65, 65, 65, 0.09) 44%,rgba(236, 236, 236, 0.09) 44%, rgba(236, 236, 236, 0.09) 100%),linear-gradient(90deg, rgba(124, 124, 124, 0.03) 0%, rgba(124, 124, 124, 0.03) 81%,rgba(138, 138, 138, 0.03) 81%, rgba(138, 138, 138, 0.03) 100%),linear-gradient(90deg, hsl(189,61%,44%),hsl(189,61%,44%));
        margin-right: 1rem; 
    }
    &__icon{
        font-size: 3.5rem;
    }
    &__heading {
        font-size: 2.5rem;
        text-transform: uppercase;
    }
    &__sub-title {
        font-size: 1.8rem;
    }
    &:hover{
        color: #ffffff ;           
        cursor: pointer;
    }
}