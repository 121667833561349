@import "./animations.scss";

@mixin moveUpChildren($children) {
    @for $i from 1 through $children {
      & > :nth-child(#{$i}) {
        animation: moveinbottom 1s ease-in-out #{$i * .1}s;
        animation-fill-mode: backwards;
      }
    }
}

@mixin moveUp($children) {
  @for $i from 1 through $children {
    &:nth-child(#{$i}) {
      animation: moveinbottom 1s ease-in-out #{$i * .1}s;
      animation-fill-mode: backwards;
    }
  }
}

@mixin iconCircle($size) {
  box-shadow: 0 0 4rem rgba(0,0,0,0.5);
  border-radius: 50%;
  width: #{$size}rem;
  height: #{$size}rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255,255,255,0.8);
  transition: 0.5s ease-out;
}

@mixin hexagon {
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}