.sidenav {
    position: fixed;
    left: 0;
    top: 0;
    padding: 5rem .5rem 1rem .5rem  ;
    background-color:  rgba(#001216, 0.2);
    height: 100vh;
    z-index: 899;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: transform .3s ease-in-out;
    &--hidden {
        transform: translateX(-80rem);
    }
    &__icon--mobile{
        margin-right: 1.5rem;
        font-size: 3.2rem;
    }
    &__item {
        text-decoration: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1rem;
        cursor: pointer;
        transition: .2s ease-out;
        color: rgba(white,0.5);
        font-size: 1.2rem;
        &--mobile{
            text-decoration: none;
            display: flex;
            margin-top: 1rem;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: .2s ease-out;
            color: rgba(white,0.5);
            font-size: 3.2rem;
            width: 70%;
            &:hover{
                color: white;
                transform: translateY(-.2rem);
            }
        }
        &:visited{
            color: rgba(white, .5);
        }
        &:hover{
            color: white;
            transform: translateX(.5rem);
        }
        &--active{ 
            color: white;
        }
    }
}

.toggle-button {
    position: fixed;
    top: 3rem;
    left: 1.8rem;
    z-index: 913;
    width: 3.3rem;
    height: 2.6rem;
    background-color: transparent;
    border: none;
    text-align:center;
    display: none;
    transition:opacity .3s ease-in;
    opacity: 0;
    cursor: pointer;
    &__icon{
        top: 1rem;
        &,
        &::after,
        &::before{
            width: 3rem;
            height: 2px;
            background-color: #ffffff;
            content:"";
            display: inline-block;
            position: absolute;
            left: 0;
            transition: all .3s ease-in-out;
        }
        &::before{
            top: -1rem;
        }
        &::after{
            top: 1rem;
        }
    }   
}

@keyframes scaleUp {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(100);
    }
}

@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateX(-50rem);
    }
    70%{
        opacity: .7;
        transform: translateX(1.5rem);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.mobile-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 910;
    &--enter-done{
        animation: slideIn 1s ease-in-out;
        animation-fill-mode: forwards;
    }
    &--exit {
        transform: translateX(0);
        opacity: 1;
    }
    &--exit-active{
        transform: translateX(-100rem);
        opacity: 0;
        transition: .2s ease-in-out;
    }
}

.background {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    position: fixed;
    background-image:radial-gradient(circle at center center, rgba(0, 62, 75, .9),rgba(0, 6, 7, .9));
    top: 1rem;
    left: 1rem;
    z-index: 900;
    &--enter {
        transform: scale(1);
    }
    &--enter-active{
        transform: scale(80);
        transition: .4s ease-in-out;
        & + .toggle-button .toggle-button__icon{
            background-color: transparent;
            &::after {
                top: 0;
                transform: rotate(135deg);
            }
            &::before {
                top: 0;
                transform: rotate(-135deg);
            }
        }
    }
    &--exit {
        transform: scale(80);
    }
    &--exit-active{
        transform: scale(1);
        transition: .4s ease;
    }
}
#test {
    background-color: pink;
}
@media (max-width: 769px){
    .sidenav {
        display: none;
    }
    .toggle-button {
        display:block;
        opacity: 1;
    }
}


