@import "css/animations.scss";
@import "css/mixins.scss";

*, *::after, *::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Lato", "sans-serif";
  font-weight: 400;
  line-height: 1.5;
  color: #f5f3f1;
  box-sizing: border-box;
}

.card-link {
  color: #003d4a;
  transition: .2s ease-in;
  &:visited {
    color: #003d4a
  }
  &:hover {
    opacity: .5;
  }
}

@media (max-width: 1024px){

  html {
    font-size: 8px;
  }
}

@media (max-width: 600px){

  html {
    font-size: 6px;
  }

  p.content, li.content, .content {
    font-size: 12px;
  }

}


