.links {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}

@media(max-width: 650px){
  .links {
    justify-content: flex-start;
    width: 55%;
  }
}